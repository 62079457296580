<template>
  <div class="home">
    <div class="navbar">
      <div class="background">
      </div>
      <div class="navbar-content">
        <img src="@/assets/ghost.png" alt="ghost" />
        <div class="Maintenance" style="color: #CCC; font-size: 18px; margin-top: 30px;">维护中QWQ  ......</div>
      </div>
    </div>
    <div>
      <div class="pageFoot">
        <div class="icp">
          <a class="icp-text" href="https://beian.miit.gov.cn/" target="_blank">
            赣ICP备2024043480号
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
<style scoped>
.home {
  height: 100vh;
  overflow-x: hidden;
}
.background {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.397);
  z-index: -1;
}
.navbar {
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.navbar-content {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 200px;
}
.navbar-content img {
  object-fit: cover;
  animation: ghostMove 3s infinite linear;
}
@keyframes ghostMove {
  50% {
    transform: translate(-4px, 10px);
  }
}
.pageFoot {
  width: 100%;
  height: 10vh;
  background-color: rgba(13, 5, 5, 0.854);
}
.pageFoot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageFoot .icp-text {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
</style>
<style>
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
}
</style>