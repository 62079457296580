import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to,from, next) => {
  if (to.path != '/') { console.log('ok'); next('/') }
  console.log(to.path)
  next();

})
export default router
